import { SaUserAssignmentDto } from '../dto/sa-user-assignment-dto';
import firebase from 'firebase/compat/app';

import { EyeColor, Gender, HairColor, UserDocument, UserPermissions } from '../dto/userDoc';
import { UserRole } from '../enums/userRole';
import { createDate } from '../utils/dates/create-date';

export class UserObj implements UserDocument {
  active?: boolean = true;
  agreement?: boolean;
  archived: boolean = false;
  authId?: string = '';
  createdBy: string = ''; // AccountHolder Id
  createdOn: Date | firebase.firestore.Timestamp;
  email?: string = '';
  employeeId: string = '';
  firstName: string = '';
  groupId: string = '';
  groupName: string = '';
  id: string = '';
  lastName: string = '';
  lastUpdatedOn: Date | firebase.firestore.Timestamp;
  location?: string = '';
  permissions: UserPermissions;
  phone?: string = '';
  tenantId: string = '';
  title?: string = '';
  groupPermissions: Array<string> = [];
  [key: string]: any;
  keywords?: Array<string> = [];
  updatedBy: string = ''; // AccountHolder Id
  username: string = '';
  userRole: UserRole = UserRole.trainee;
  sqlId?: number = null;
  sqlTenantId?: number = null;
  sqlGroupId?: number = null;
  canCreateTenant?: boolean;
  saAssignments?: SaUserAssignmentDto;
  saAssignmentStatus?: string;
  disabled?: boolean;
  note?: string;
  qualificationIds: Array<string>;
  qualificationAssignmentIds: Array<string>;
  profilePictureUrl?: string;
  physicalities?: {
    dob: Date | firebase.firestore.Timestamp;
    gender: Gender;
    height: number;
    weight: number;
    hairColor: HairColor;
    eyeColor: EyeColor;
  };
  costCenter?: string = null;
  division?: string = null;
  zone?: string = null;
  payId?: string = null;
  budgetGroup?: string = null;
  isSparkUser?: boolean = false;
  manager?: string = null;
  foreignRailroad?: string = null;

  /**
   * Constuctor that takes a generic object and copies all matching properties
   * setting default values for any missing properties.
   * @param properties
   */
  public constructor(properties?: any) {
    if (properties !== undefined) {
      Object.keys(properties).forEach((key) => {
        if (this.hasOwnProperty(key)) {
          // Only shows properties with default values set.
          this[key] = properties[key];
        }
      });
      this.agreement = properties?.agreement ?? null;
    }
    // Special case for agreement which can be left blank
    if (properties && properties['agreement']) {
      this.agreement = properties['agreement'];
    }
    // Definatively assigning a value to fields which cannot be null
    this.createdOn = properties && properties['createdOn'] ? createDate(properties['createdOn']) : new Date();
    this.lastUpdatedOn = properties && properties['lastUpdatedOn'] ? createDate(properties['lastUpdatedOn']) : new Date();
    if (properties && properties['permissions']) {
      this.permissions = properties['permissions'];
      if (this.permissions.qualificationsModule === undefined) {
        this.permissions.qualificationsModule = {
          edit: false,
          view: false,
        };
        this.permissions.manageMobileQualifications = false;
      }
      if (this.permissions.onboardingModule === undefined) {
        this.permissions.onboardingModule = {
          edit: false,
          view: false,
        };
      }
    } else {
      this.permissions = {
        checklists: false,
        users: false,
        groups: false,
        reports: false,
        designateInstructors: false,
        limitedTrainees: false,
        limitedSignOff: false,
        testSupervisor: false,
        testAdminView: false,
        testAdminEdit: false,
        safetyAlertsView: false,
        safetyAlertsEdit: false,
        daModuleEdit: false,
        daModuleView: false,
        daModuleViewOrder: false,
        daModuleOrder: false,
        utModuleEdit: false,
        utModuleOrder: false,
        randomizerModuleEdit: false,
        randomizerModuleView: false,
        manageMobileQualifications: false,
        qualificationsModule: {
          view: false,
          edit: false,
        },
        onboardingModule: {
          edit: false,
          view: false,
        },
        datEView: false,
        datEEdit: false,
      };
    }
    if (properties?.canCreateTenant) {
      this.canCreateTenant = properties.canCreateTenant;
    }

    if (properties?.qualificationIds) {
      this.qualificationIds = properties.qualificationIds;
    }
    if (properties?.qualificationAssignmentIds) {
      this.qualificationAssignmentIds = properties.qualificationAssignmentIds;
    }

    if (properties?.profilePictureUrl) {
      this.profilePictureUrl = properties.profilePictureUrl;
    }

    if (properties?.physicalities) {
      this.physicalities = properties.physicalities;
    }

    if (properties?.costCenter) {
      this.costCenter = properties.costCenter;
    }

    if (properties?.division) {
      this.division = properties.division;
    }

    if (properties?.zone) {
      this.zone = properties.zone;
    }

    if (properties?.payId) {
      this.payId = properties.payId;
    }

    if (properties?.budgetGroup) {
      this.budgetGroup = properties.budgetGroup;
    }

    if (properties?.groupName) {
      this.groupName = properties.groupName;
    }

    if (properties?.isSparkUser) {
      this.isSparkUser = properties.isSparkUser;
    }
    if (properties?.manager) {
      this.manager = properties.manager;
    }
    if (properties?.foreignRailroad) {
      this.foreignRailroad = properties.foreignRailroad;
    }
  }

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export class DIAndQPDashboardUser extends UserObj {
  expirationDate: Date | null;
  userType: 'DI' | 'QP';

  constructor(params: DIAndQPDashboardUser) {
    super(params);
    this.expirationDate = params.expirationDate || null;
    if (!params.userType) {
      throw new Error('Type is required');
    }
    this.userType = params.userType;
  }
}
